<mat-toolbar>
  <mat-toolbar-row>
    <button
      aria-label="Toggle sidenav"
      mat-icon-button
      type="button"
      (click)="clickNav()"
    >
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <app-breadcrumb />
    <span class="spacer"></span>
    @if (loggedIn$ | async) {
    @defer () {
    <app-notification />
    }
    }
  </mat-toolbar-row>
  <mat-toolbar-row
    id="toolbar-tab-container"
    [style.viewTransitionName]="viewTransitionName()"
  />
</mat-toolbar>
