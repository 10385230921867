@if ((breadcrumbs$ | async); as crumbs) {

@if (crumbs.length) {
<nav>
  <ol class="breadcrumb">
    @for (crumb of crumbs; track crumb?.label; let last = $last; let first = $first) {
    <li
      class="breadcrumb-item"
      [class.active]="last"
    >
      <span>
        @if (!first) {
        <mat-icon>chevron_right</mat-icon>
        }
        @if (!last) {
        <a [routerLink]="crumb.url">{{ crumb.label }}</a>
        }
        @if (last) {
        <span>{{ crumb.label }}</span>
        }
      </span>
    </li>
    }
  </ol>
</nav>
}

}